$billerq: #534686;

.billerq_color {
  color: $billerq;
}

.billerq_background {
  background: $billerq;
  color: white;
}

.rdt_TableHead {
  h4 {
    font-size: 14px;
    letter-spacing: normal;
  }
}

.rdt_TableHeadRow {
  background: rgb(218, 218, 218);
}

.rdt_Table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}



.loader-transparent {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.299);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    display: block;
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary-color;
    animation: loader 0.5s linear infinite reverse;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $primary-color;
    }

    &:before {
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      animation: loader 0.3s linear infinite forwards;
    }

    &:after {
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      animation: loader 0.7s linear infinite reverse;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.badge {
  background: #f3f3f3;
  color: #000;
}

.badge-inactive {
  background: crimson;
  color: #fff;
}

.badge-no {
  background: crimson;
  color: #fff;
}

.badge-suspended {
  background: #b793d2;
  color: #fff;
}

.badge-active {
  background: green;
  color: #fff;
}

.badge-yes {
  background: green;
  color: #fff;
}

.badge-terminated {
  background: crimson !important;
  color: #fff;
}


.badge-archive {
  background: #ffa47a;
  color: #fff;
}

.badge-suspended {
  background: #b793d2;
  color: #fff;
}


.badge-processed {
  background: #67c100;
  color: #fff;
}

.badge-disconnected {
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
}

.badge-pending {
  background: #ffa47a;
  color: #fff;
}

.badge-free {
  background: #ffa47a;
  color: #fff;
}

.badge-partially {
  background: #0e1d6f;
  color: #fff;
}

.badge-partial {
  background: #0e1d6f;
  color: #fff;
}

.badge-paid {
  background: #67c100;
  color: #fff;
}

.badge-received {
  background: #67c100;
  color: #fff;
}

.badge-closed {
  background: #67c100;
  color: #fff;
}

.badge-open {
  background: crimson;
  color: #fff;
}

.badge-complete {
  background: green;
  color: #fff;
}

.badge-in_progress {
  background: #0e1d6f;
  color: #fff;
}

.badge-in_terminated {
  background: #75788c;
  color: #fff;
}

.order-box {
  width: 25%;

  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        margin-left: 30px;
        font-weight: bold;
      }
    }
  }
}

@media print {
  .invoice-print-styling {
    padding: 40px;

    .invo-header {
      margin-top: 20px;
    }
  }
}

.sroller-style {

  /* Vertical Scrollbar */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
    border: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cbcbcb;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #cac5c5;
  }

  /* Horizontal Scrollbar */
  &::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
    border: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cbcbcb;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
    background-color: #cac5c5;
  }

}